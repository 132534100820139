<template>
  <div class="spread">
    <basic-info @name-event="handleEvent" :value="value" :name-label="'Location Name'" @input="update" />
    <main-address :value="value.address.data" @input="updateAddress" />
    <settings :value="value" :selected-mvr="selectedMvrPackage" :selected-screening="selectedScreeningPackage" :package-list="packageList" @input="update" />
    <div class="module-form">
      <h3>Funnel Settings</h3>
      <div class="logo-preview">
        <file-viewer :image-id="map" />
      </div>
      <!-- <file-upload label="Change Delivery Area Map" :client-location-id="$route.params.locationID" filename="deliveryAreaMap" :public="true" @change="onMapChange" /> -->
      <file-upload-logo label="Change Delivery Area Map" :location-id="$route.params.locationID" filename="deliveryAreaMap" :public="true" @change="onMapChange" />
    </div>
    <team-setup :accountManagers="$props.accountManagers" :specialists="$props.specialists" @onAMChanged="onAMChanged" @onSpecialistChanged="updateSpecialist" />
  </div>
</template>

<script>
import { timeZones } from "@/util/timeZones";
import { BasicInfo, MainAddress, Settings, TeamSetup } from "@/components";
import FileUploadLogo from "@/components/files/FileUploadLogo";
// import { SET_CLIENT_LOCATION_DELIVERY_AREA_MAP } from "@/modules/admin/clients/graph/mutations";
// import { hardDeleteFile } from "@/util/filesHelper";
import FileViewer from "@/components/files/FileViewerLocation";
import { restApi } from "@/http/http.config";
import { encodeWithParam } from "@/filters";
import { convertBufferToContent } from "@/util/BufferContentConverter";

export default {
  name: "LocationForm",
  components: { TeamSetup, BasicInfo, MainAddress, Settings, FileViewer, FileUploadLogo },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    teams: { type: Array, required: false, default: () => [] },
    selectedTeam: { type: Array, required: false, default: () => [] },
    accountManagers: { type: Array, required: false, default: () => [] },
    specialists: { type: Array, required: false, default: () => [] },
    selectedSpecialist: { type: Array, required: false, default: () => [] },
    selectedAM: { type: Array, required: false, default: () => [] },
    // finalOne : {type: Boolean}
  },
  data: () => {
    return {
      flyout: false,
      managers: [],
      timeZones: timeZones,
      isValid: Boolean,
      map: "",
      packageList: [],
    };
  },
  computed: {
    selectedMvrPackage() {
      return this.$props.value.mvrPackageName ? [{ id: this.$props.value.mvrPackageName, name: this.$props.value.mvrPackageName }] : [];
    },
    selectedScreeningPackage() {
      return this.$props.value.screeningPackageName ? [{ id: this.$props.value.screeningPackageName, name: this.$props.value.screeningPackageName }] : [];
    },
  },
  // mounted() {
  //   // api.get(`/onboarding/checkr/packages`).then((result) => {
  //   //   this.packageList = result.data.map((item) => {
  //   //     return { id: item.slug, name: item.slug };
  //   //   });
  //   // });
  // },
  methods: {
    handleEvent(data){
      // console.log(data,"2nd page")
      this.$emit('finalname-event',data)

    },
    update(value) {
      this.$emit("input", { ...this.$props.value, ...value });
    },
    updateAddress(value) {
      this.$emit("input", { ...this.$props.value, address: { data: { ...value } } });
    },
    onAMChanged(itemChangedEvent) {
      this.$emit("onAMChanged", itemChangedEvent);
    },
    updateSpecialist(itemChangedEvent) {
      this.$emit("onSpecialistChanged", itemChangedEvent);
    },
    async onMapChange(data) {
      // console.log("data from component", data);
       let data1 = await restApi.post('/employee/getUploadedFile',encodeWithParam({fileID: data.fileID}))
      this.map=convertBufferToContent(data1.data.bufferInHex,data1.data.contentType)
      this.$emit("input", { ...this.$props.value, deliveryAreaMap: data?.fileID });
    },
  },
};
</script>
